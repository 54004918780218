.about {
  table, .loading {
    margin: 2em auto;
    width: fit-content;
    display: block;
  }

  th, td {
    padding: 0.5em;
  }

  .loading {
    font-size: 2em;
  }
  
}