// ***********************************
// **   COMPONENT: Carousel   **
// ***********************************

#lightboxBackdrop img {
    object-fit: contain;
}

.carousel-wrapper {
    /*
  img {
    max-width: 100%;
    height: auto;
  }
  */

    .carousel-item {
        // max-height: 10em;
        height: auto;
        min-height: 300px;
        height: calc(100vh - 254px);
        overflow: hidden;
        position: relative;
    }

    .carousel-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: black;
        transition: 0.2s;
        opacity: 0.2;
        z-index: 1;
    }

    .carousel-label {
        z-index: 10000;
        position: absolute;
        padding: 2%;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 1em;

        &.middle {
            top: 15%;
            left: 15%;
            width: 70%;
        }

        &.top {
            top: 1%;
            left: 15%;
            width: 70%;
        }

        &.bottom {
            bottom: 1%;
            left: 15%;
            width: 70%;
            z-index: 2;

            a:hover,
            a:focus,
            a:active {
                color: #dcb500;
                opacity: 1;
            }
        }
    }

    h2 {
        padding: 0 0 0.3em 0;
        // margin: 0 0 0.5em 0;
        color: #fde777;
        // font-size: 2.5vw;
        font-size: 2.3em;
        line-height: 100%;
        line-height: 115%;
        word-break: break-word;
    }

    .sub-title {
        padding: 0;
        padding: 0;
        color: white;
        // font-size: 1.5vw;
        font-size: 1.2em;
        line-height: 150%;
    }

    h2,
    .sub-title {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
    }
}

.carousel-item {
    width: 100%;
    min-height: 20em;
}

.carousel-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

/*
.slide-1 {
  background: url(../../images/carousel/carousel_1_big.jpg) no-repeat center;
}
.slide-2 {
  background: url(../../images/carousel/carousel_2_big.jpg) no-repeat center;
}
.slide-3 {
  background: url(../../images/carousel/carousel_3_big.jpg) no-repeat center;
}
.slide-4 {
  background: url(../../images/carousel/carousel_4_big.jpg) no-repeat center;
}
*/
/*
.slide-1 {
  background: url(../../images/carousel/carousel_1_big.jpg) no-repeat center;
}
.slide-2 {
  background: url(../../images/carousel/carousel_2_big.jpg) no-repeat center;
}
.slide-3 {
  background: url(../../images/carousel/carousel_3_big.jpg) no-repeat center;
}
.slide-4 {
  background: url(../../images/carousel/carousel_4_big.jpg) no-repeat center;
}
*/

.slide-5 {
    position: relative;
    background-color: transparent;
    background-color: #fff2b6;

    .blog-img-1 {
        background: url(../../images/carousel/blog-top-left.png) no-repeat;
        background-position: top left;
        background-size: contain;
        position: absolute;
        left: 0px;
        top: 0px;
        // width: 12%;
        width: 35%;
        height: inherit;
        z-index: 0;
    }

    .blog-img-2 {
        background: url(../../images/carousel/carousel-blog-title.png) no-repeat
            center;
        background-size: contain;
        position: relative;
        width: 40%;
        height: inherit;
        height: inherit;
        margin: 0 auto;
        z-index: 1;
    }

    .blog-img-3 {
        background: url(../../images/carousel/blog-bottom-right.png) no-repeat;
        background-position: right bottom;
        background-size: contain;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 35%;
        height: inherit;
        z-index: 0;
    }
}

// ***********************************
// **   Media queries: Responsive   **
// ***********************************

/* sm - Small devices (tablets and up) */
@media screen and (min-width: 768px) {
    .carousel-wrapper .carousel-item .carousel-label.middle {
        top: 35%;
    }

    .slide-5 {
        .blog-img-1 {
            width: 28%;
        }

        .blog-img-2 {
            width: 35%;
        }

        .blog-img-3 {
            width: 25%;
        }
    }
}

/* md - Medium devices (desktops and up) */
@media screen and (min-width: 992px) {
    .slide-5 {
        .blog-img-1 {
            width: 22%;
        }

        .blog-img-2 {
            width: 30%;
        }

        .blog-img-3 {
            width: 20%;
        }
    }
}

/* lg - Large devices (large desktops and up) */
@media screen and (min-width: 1200px) {
    .slide-5 {
        .blog-img-1 {
            width: 18%;
        }

        .blog-img-2 {
            width: 23%;
        }

        .blog-img-3 {
            width: 15%;
        }
    }
}
