// *********************************
// **   Global styles             **
// *********************************
h1,
h2,
h3 {
  margin: 1.2em 0 0.4em 0;
}

p {
  margin: 1em 0;
}

main {
  padding-bottom: 5em;
}

// *********************************************************************
// **   Common - General (some overrides from default template)       **
// *********************************************************************

a {
  color: #dcb500;
}

a:hover,
.widget_categories li span:hover,
.widget_categories li .post-details .tags a:hover,
.post-details .tags .widget_categories li a:hover,
a:active,
.widget_categories li span:active,
.widget_categories li .post-details .tags a:active,
.post-details .tags .widget_categories li a:active,
a:focus,
.widget_categories li span:focus,
.widget_categories li .post-details .tags a:focus,
.post-details .tags .widget_categories li a:focus {
  color: #79660e;
  opacity: 0.6;
}

#overlay {
  position: fixed;
  width: 100%;
  background: black;
  // opacity: 0.75;
  // display: none;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.7s ease-out;
  height: 0; // This is how I manage to take it out without using display none
}

body.show-overlay {
  overflow: hidden;

  #overlay {
    // display: block;
    opacity: 0.75;
    height: 100%;
  }
}

// Some padding/margin for ul and li
.content {
  ul {
    padding: 1em;
  }

  li {
    margin: 1em;
  }
}

// Default line height
.content,
li,
p {
  line-height: 1.5em;
  font-size: 1.1em;
  text-align: justify;
  color: #777777;
}

.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
  max-height: 500px;
}

.navbar-default .navbar-nav li a,
.navbar-default .navbar-nav .widget_categories li span,
.widget_categories .navbar-default .navbar-nav li span {
  color: #777777;
  font-size: 1.2em;
  text-align: justify;
}

.service-single p,
.service-single .post-single .read-more,
.post-single .service-single .read-more,
.service-single .post-details .post-desk ul,
.post-details .post-desk .service-single ul {
  color: #777777;
  opacity: 1;
  font-size: 1.05em;
  line-height: 1.8em;
  text-align: justify;
}

.service-icon .fa {
  font-size: 4em;
}

.section-header .sub-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.4em;
  width: 90%;
  text-align: justify;

  margin-bottom: 2.8em;
}

.section-header .title {
  margin-bottom: 0.5em;
}

// Default subtitle (2 color title sections)
//  i.e. <span class="section-sub-title">Nuestras&nbsp;<span>instalaciones</span></span>
// IMPORTANT: Overrided for responsive (see media queries below)
.section-sub-title {
  float: none;
  display: block;
  text-align: center;
  font-size: 2.2em;
  line-height: 1.2em;
  margin: 1.5em 0 1em 0;
}

.section-sub-title .fa {
  color: #d2ae0f;
}

// Bigger subtitle to be added after a h1.title
.sub-title-hightlight {
  line-height: 2em;
  font-size: 0.5em;
  color: #777777;
  padding: 0 1em;
  // padding: 0 1em;
}

// Section: Block with a title and some content
.section {
  margin: 2em 0;

  ul {
    margin: 0 2em;
  }

  li {
    margin: 1em 0;
    line-height: 1.5em;
    text-align: left;
  }

  h2.sub-title {
    line-height: 1.2em;
    padding: 0 1em;
  }

  p.sub-title {
    line-height: 2em;
    padding: 0 1em;
  }
}

// ***********************************
// **   Forms   **
// ***********************************

// Button area
.btn-area {
  text-align: center;
}

// Show error for inputs: Helper text
.has-error {
  .control-label {
    color: #7a7a7a;
  }

  .help-block {
    color: #d82d29;
    visibility: visible;
  }
}

// Hide the helper text for showing error on inputs
.help-block {
  visibility: hidden;
}

// Show error on inputs: red border
.form-control.has-error,
form .form-group.has-error textarea {
  border-color: #d82d29;
}

.btn-primary {
  color: #79660e;
  background-color: #fde777;
  border-color: #d2ae0f;
}

.btn-primary:hover {
  color: #79660e;
  background-color: #fbd617;
  border-color: #d2ae0f;
}

// *********************************
// **   Header                    **
// *********************************

.header .container-fluid {
  margin: 0;
}

.header .social-icons {
  display: block;
  padding-top: 1em;
}

.header.nav-up .social-icons {
  display: none;
}

#navbar .social-icons-menu-item .social-icons {
  display: block;
  z-index: 100;
}

#navbar .social-icons,
.nav-down.header .social-icons {
  display: none;
}

.header .navbar-header .social-icons {
  padding-top: 0.5em;
}

// Default header: Big
header {
  height: 16em;

  .responsive-logo {
    padding: 0 0 0 0;
    text-align: right;
    float: right;

    img {
      height: 45px;
    }

    .navbar-brand span {
      font-size: 1.3em;
      color: black;
    }
  }

  .container-fluid {
    margin: 0;
  }

  .social-icons {
    position: absolute;
    width: 8em;
    text-align: center;
    top: 0;
    right: 0;

    a {
      padding: 0.4em;
    }

    .fa {
      font-size: 1.5em;
    }
  }
}

body {
  div.logo {
    display: block;
    height: 15em;
    opacity: 1;
    position: relative;

    a:hover {
      opacity: 0.7;
    }

    // Display logo: when big header
    img {
      display: inline-block;
      max-width: 25em;
      height: 100%;
      padding: 1em 0 0 0;

      svg {
        fill: currentColor;
      }
    }
  }

  div.logo,
  a.navbar-brand,
  nav {
    transition-timing-function: ease-out;
    transition: 0.7s all;
    -moz-transition: 0.7s all;
    -webkit-transition: 0.7s all;
    -o-transition: 0.7s all;
  }
}

// Navbar: Menu with sections
#navbar {
  position: relative;
  padding: 0;

  // Leave some space for the social-icons
  // padding-right: 15%;

  border-top: 1px solid #e5e5e5;

  .navbar-nav {
    float: none;
    margin: 0 auto;
    display: table;
    table-layout: fixed;

    i {
      margin: 0 1em 0 0.3em;
    }

    li:first-child a {
      padding-top: 3em;
    }

    li a {
      border-top: 1px solid #f5f5f5;
      padding: 1em 1.3em;
      font-size: 1em;
    }

    li a:hover {
      background-color: #f5f5f5;
    }
  }
}

.navbar-toggle {
  margin-right: 2.5em;
}

.navbar-default .navbar-nav .has-sub-menu>.sub-menu {
  margin-left: 0;
}

// Logo positioning for small header
// IMPORTANT: Overrided for responsive (see media queries below)
a.navbar-brand img {
  opacity: 0;
  margin: -14px 0 0 0;
  padding: 0.1em 0.1em 0.1em 1em;
}

// Hide navbar: when we scroll down
.nav-up nav {
  top: -72px;
}

// Navbar link style
.navbar-default .navbar-nav li a.active {
  color: #353535;
  opacity: 1;
}

// Small header: when you scroll down
body.small-header {

  // small
  header {

    // height: 5em;
    & .responsive-logo {
      float: left;
    }
  }

  // Hide logo
  div.logo {
    opacity: 0;
    height: 0;
    padding: 0;
    contain: '';
  }

  div.logo img {
    display: none;
  }

  a.navbar-brand span {
    display: none;
  }

  a.navbar-brand img {
    opacity: 1;
  }
}

// *********************************
// **   Page title heading        **
// *********************************
// The header image on top of the secondary pages
.page-title {
  /* background-image: url(./images/mesa-header-light.jpg); */
  /* background-color: rgba(0,0,0,0.5); */
  background-color: #fff3b6;
  background-image: none;
  padding: 1em 0;
  border: 1px solid #d2ae0f;

  h2 {
    font-size: 2.5em;
    font-family: 'Open Sans', sans-serif;
    color: #d2ae0f;
    margin: 1.5em 0 1em 0;
    line-height: 1.1em;
  }
}

// *************************************
// **   Alert     **
// *************************************

.alert>p+p {
  margin-top: 10px;
}

// *************************************
// **   Component: Quick buttons      **
// *************************************
// Buttons in the middle of the web to allow quick actions
.quick-buttons {
  margin-top: 2.5em;
  text-align: center;

  button,
  a {
    margin: 0.6em 0.3em;
  }

  a {
    display: inline-block;
  }
}

// ***********************************
// **   COMPONENT: Map   **
// ***********************************

.map-group {
  text-align: center;
}

.responsive-visit-us {
  display: inline-block;
  max-width: 30em;
  margin: 1.5em auto 2em auto;
  font-size: 1em;
}

.responsive-visit-us .fa {
  font-size: 3em;
  margin: 0.3em;
}

.responsive-visit-us h6 {
  padding: 0.4em 0;
}

// IMPORTANT: Overrided for responsive (see media queries below)
.map-tooltip {
  width: 250px;
}

// *********************************
// **   Component: Loading        **
// *********************************
// Loading animation
@keyframes blink {

  // At the start of the animation the dot has an opacity of .2
  0% {
    opacity: 0.2;
  }

  // At 20% the dot is fully visible and then fades out slowly
  20% {
    opacity: 1;
  }

  // Until it reaches an opacity of .2 and the animation can start again
  100% {
    opacity: 0.2;
  }
}

.loading span {
  // Use the blink animation, which is defined above
  animation-name: blink;

  // The animation should take 1.4 seconds
  animation-duration: 1.4s;

  // It will repeat itself forever
  animation-iteration-count: infinite;

  /*
    This makes sure that the starting style (opacity: .2) of the animation is 
    applied before the animation starts.
    Otherwise we would see a short flash or would have to set the default
    styling of the dots to the same as the animation.
    Same applies for the ending styles.
  */
  animation-fill-mode: both;
}

// Starts the animation of the 2nd dot with a delay of .2s, otherwise all dots
// would animate at the same time
.loading span:nth-child(2) {
  animation-delay: 0.2s;
}

// Starts the animation of the third dot with a delay of .4s, otherwise all dots
// would animate at the same time
.loading span:nth-child(3) {
  animation-delay: 0.4s;
}

// *********************************
// **   Component: Contact form   **
// *********************************

.contact-form {
  .form-control {
    /* margin-bottom: 2em; */
    margin-bottom: auto;
  }

  label {
    margin-top: 1em;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 12px;
    color: #7a7a7a;
  }

  textarea {
    margin: 0.5em 0 auto 0;
    border-top: 1px solid #cfcfcf;
    line-height: 1em;
    padding-top: 1em;
    text-transform: none;
  }

  textarea.message {
    height: 10em;
  }

  textarea.dateDetails {
    height: 5em;
  }
}

#contact2 {
  h6 {
    margin-bottom: 0.35em;
  }

  // Raise a bit the icons at the left of the contact form
  /*
    .location {
      margin-top: -8em;
    }
    */

  .item p {
    margin-top: 0;
    text-align: center;
  }
}

a.whatsapp {
  color: #25d366;
}

i.fa.fa-whatsapp {
  font-size: 1.6em;
}

// *********************************
// **   Prices                    **
// *********************************

#pricing {
  padding: 0 1em;

  h1.title {
    // margin-bottom: 1em;
  }

  h2 {
    padding: 0 0 0.3em 0;
    margin: 0;
  }

  // Card hover efect
  .price-table:hover {
    box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.33);
    margin-top: default;
    padding: default;
  }

  // Plan price description: Message below the price
  .price-table .header .plan-limit,
  .plan-name {
    line-height: 1.5em;
  }

  // Reference: Red asterisc on prices
  .reference {
    color: red;
    padding: 0 0.2em;
  }

  // Price color
  .price-table h2.price,
  span.price {
    color: #337ab7;
  }

  // Price style
  span.price {
    font-weight: 600;
    font-size: 1.5em;
  }

  .price-table {
    // margin: 2em 0 3em 0;
    margin: 3em 0;

    .overlay {
      padding: 6em 3.5em 4em 3.5em;
    }
  }

  .price-table.price-selected {
    margin-top: 0.5em;

    .overlay {
      padding-bottom: 9em;
    }
  }

  .price-table .list li {
    line-height: 1.5em;
  }

  // Price Footer: Do reservation link
  .footer {
    text-align: center;
  }

  // Disclaimer: Message on the bottom of the prices table
  .price-disclaimer {
    padding: 1em;
    text-align: left;
    font-size: 1.2em;
    line-height: 2em;
    max-width: 30em;
    margin: 0 auto;
  }

  .price-disclaimer .reference {
    width: 2em;
    display: inline-block;
  }

  // Pricing styles
  .price-block {
    margin-bottom: 30px;
  }

  .price-header {
    background-color: #d2ae0f;
    color: white;
    padding: 15px 20px;
    font-size: 24px;
    margin: 0;
    border-radius: 8px 8px 0 0;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  .price-content {
    border: 2px solid #d2ae0f;
    border-top: none;
    border-radius: 0 0 8px 8px;
    padding: 20px;
    background-color: white;
  }

  .price-content-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0px;
  }

  .price-content-main {
    flex: 1;
  }

  .price-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .price-list li {
    margin-bottom: 20px;
    padding-left: 30px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
  }

  .price-list .fa-check-circle, .price-list .fa-glass {
    color: #d2ae0f;
    position: absolute;
    left: 0;
    top: 3px;
    font-size: 20px;
  }

  .price-amount {
    display: block;
    font-weight: bold;
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
  }

  .price-time {
    display: block;
    color: #666;
    font-size: 14px;
  }

  .mt-4 {
    margin-top: 2rem;
  }

  @media (max-width: 991px) {
    .price-content-flex {
      flex-direction: column;
    }

    // Remove margin from the last li of each price-list
    .price-list:last-child li {
      margin-bottom: 0;
    }
  }
}

// ************************************
// **   Section: What do we offer    **
// ************************************

#what-do-we-offer {
  h2 {
    font-size: 1.6em;
    line-height: 1.2em;
    margin: 0.6em 0;
    box-sizing: border-box;
    text-align: center;
  }

  figure {
    margin-bottom: 20px;
  }
}

// ************************************
// **   Section: Service             **
// ************************************

#service {
  text-align: center;

  .section-header {
    margin-bottom: 1.5em;
  }

  h2 {
    font-size: 1.6em;
    margin: 1em 0 0.5em 0;
  }

  a {
    font-size: 0.9em;
  }

  .fa {
    margin: 0 0.3em 0 0.1em;
  }

  .service-single {
    margin-bottom: 3em;
  }
}

// ************************************
// **    Section: Our facilities     **
// ************************************
#our-facilities {
  h2 {
    font-size: 1.6em;
    text-align: center;
    margin: 1em 0 0 0;
  }

  .service-single {
    margin-bottom: 1em;

    figure {
      margin-bottom: 0px;
    }
  }
}

// ************************************
// **    Section: Our facilities     **
// ************************************

#how-does-it-work {
  .media-left img {
    width: 45px;
    height: 45px;
  }

  .media-left .fa {
    font-size: 2.5em;
  }

  .media:first-child {
    margin-top: 0;
  }

  .media {
    margin-top: 2.5em;
  }

  .fa-wpforms {
    font-size: 1.2em;
  }
}

// IMPORTANT: Overrided for responsive (see media queries below)

// ********************************
// **    Footer                  **
// ********************************
footer {
  margin-top: 2em;
  padding: 3em 0 2em 0;
  position: relative;

  h2.widget-title {
    padding: 0;
    margin: 0;
  }

  .widget .widget-title {
    margin: 0.8em 0 1.8em 0;
  }

  p,
  li,
  div {
    text-align: center;
  }

  div.media-body {
    text-align: left;
  }

  .social {
    margin: 1em 0.5em;

    .fa {
      font-size: 1.5em;
    }

    a {
      margin: 0 0.8em;
    }
  }

  .policies {
    margin: 2em 0.8em;
    color: #a7a7a7;
    font-size: 1.3em;
    line-height: 1.2em;

    a {
      font-size: 0.65em;
      color: #ffffff;
      display: inline-block;
    }
  }

  .version {
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    padding: 2rem;
    font-size: 0.75em;
    color: #777777;
  }

  .widget_about {
    p {
      font-size: 1.3em;
      color: #fbd617;
    }

    .widget-logo {
      margin: 0 0 0.5em 0;

      img {
        max-width: 500px;
        max-height: 6em;
      }
    }
  }

  .widget_contact ul {
    // padding: 0 12%;
    max-width: 20em;
    margin: 0 auto;

    li {
      text-align: left;
    }
  }
}

// ***********************************
// **   Cookies                        **
// ***********************************

.cookie-policy table {
  font-size: 0.8em;
}

.table>thead>tr>th,
.table>tbody>tr>th {
  vertical-align: middle;
}

.cookie-policy th {
  background-color: #ececec;
  font-weight: 800;
  text-align: center;
}

.cookie-policy .table-title {
  color: black;
  font-weight: 800;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.cookie-policy ul {
  padding: 0 0 0 1em;
}

// ***********************************
// **   Media queries: Responsive   **
// ***********************************

/* sm - Small devices (tablets and up) */
@media screen and (min-width: 768px) {
  #how-does-it-work .media-left img {
    width: 60px;
    height: 60px;
  }

  .section-sub-title {
    font-size: 3em;
  }

  .map-tooltip {
    width: 450px;
  }
}

/* md - Medium devices (desktops and up) */
@media screen and (min-width: 992px) {
  .logo {
    padding: 1.5em 0;
  }

  #navbar {
    padding: 0 8em 0 0;
  }

  .navbar-header {
    display: none;
  }

  #navbar .navbar-nav li:first-child a {
    padding: 1em 1.3em;
  }

  #navbar .social-icons {
    display: block;
  }

  a.navbar-brand span {
    display: none;
  }

  #how-does-it-work .media-left img {
    width: 75px;
    height: 75px;
  }

  /*
  #navbar .navbar-nav {
    margin: 0 0 0 0;
  }
  */

  a.navbar-brand img {
    margin: -10px 0 0 0;
  }
}

@media screen and (min-width: 1100px) {
  .navbar-header {
    display: block;
  }
}

/* non standard: just for the menu. TODO: Review  */
/*
@media screen and (min-width: 1200px) {
  #navbar .navbar-nav {
    margin: 0 0 0 10%;
  }
}
*/

/* lg - Large devices (large desktops and up) */
@media screen and (min-width: 1200px) {
  #how-does-it-work .media-left img {
    width: 100px;
    height: 100px;
  }

  /*
  #navbar .navbar-nav {
    margin: 0 0 0 25%;
  }

  /*
  .carousel-wrapper {
    h2 {
      font-size: 300%;
    }
  
    .sub-title {
      font-size: 180%;
    }
  }
  */
}